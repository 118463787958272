import axios from 'axios'
import pako from 'pako'

import Modelu from '../utils/Module' // 引入设置的全局版本号  时间戳  uuid 或者 拼接的
let version // 设置变量 用于设置请求头的值
let Time
let Sign
let signStr
let uid
let token

axios.defaults.timeout = 100000 // 设置请求超时的时间
// axios.defaults.withCredentials = true
// axios.defaults.crossDomain = true
// axios.defaults.headers.post['Content-Type'] =
//   'application/x-www-form-urlencoded'
// axios.defaults.headers = {
//   'X-Requested-With': 'XMLHttpRequest',
// }

axios.interceptors.request.use(
  (config) => {
    let req = {
      'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Type': 'application/json;charset=UTF-8;text/html',
      'X-Requested-With': 'XMLHttpRequest',
      /**
       * TO DO
       * 如果跨域请求要想操作cookie，需要满足3个条件：
       * 服务器
       */
      // withCredentials: true, // 服务的响应头中需要携带Access-Control-Allow-Credentials并且为true
      // 'Access-Control-Allow-Origin': 'http://192.168.3.3', // 响应头中的Access-Control-Allow-Origin一定不能为*，必须是指定的域名
      // 'Access-Control-Allow-Credentials': true, // 浏览器发起ajax需要指定withCredentials 为true
      // 'Access-Control-Request-Method': 'GET,PUT,POST,DELETE',
      // 'Access-Control-Request-Headers': 'Content-Type',
      // Origin: 'http://192.168.3.3',
    }
    if (
      config.data.cmd == '100003' ||
      config.data.cmd == '100001' ||
      config.data.cmd == '202444' ||
      config.data.cmd == '200020'
    ) {
      /**
       * 无需令牌类
       * user,time,pwd/code,px
       * 注册登录
       */
      version = Modelu.VersionEvent()
      Time = Modelu.TimeEvent()
      Sign = Modelu.SignEvent()
      signStr = Modelu.signStrFunc()
    } else if (config.data.cmd === '100002' || config.data.cmd == '100005') {
      /**
       * 无需令牌类
       * version,time,px
       * 验证码
       */
      version = Modelu.VersionEvent()
      Time = Modelu.TimeEvent()
      Sign = Modelu.SignCodeEvent()
      signStr = Modelu.signStrFunc()
    } else {
      /**
       * 有令牌访问
       * uid,time,token,px
       */
      let userInfo = JSON.parse(localStorage.getItem('user-info'))
      version = Modelu.VersionEvent()
      Time = Modelu.TimeEvent()
      Sign = Modelu.SignHaveUid()
      signStr = Modelu.signStrFunc()
      token = userInfo[1].data.player.uid
    }
    if (
      config.data.cmd == '100001' ||
      config.data.cmd == '100002' ||
      config.data.cmd == '100003' ||
      config.data.cmd == '202444' ||
      config.data.cmd == '200020'
    ) {
      config.headers = { ...req, version, Time, Sign, signStr }
    } else {
      config.headers = { ...req, version, Time, Sign, signStr, token }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    if (response.data.hash) {
      return response
    } else {
      unzip(response.data)
    }
    function unzip(b64Data) {
      var strData = atob(b64Data)
      // Convert binary string to character-number array
      var charData = strData.split('').map(function(x) {
        return x.charCodeAt(0)
      })
      // Turn number array into byte-array
      var binData = new Uint8Array(charData)
      // // unzip
      var data = pako.inflate(binData)
      Utf8ArrayToStr(data) //  执行这步再将数据转换一次 就不会出现乱码的情况
    }
    function Utf8ArrayToStr(array) {
      var out, i, len, c
      var char2, char3

      out = ''
      len = array.length
      i = 0
      while (i < len) {
        c = array[i++]
        switch (c >> 4) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
            // 0xxxxxxx
            out += String.fromCharCode(c)
            break
          case 12:
          case 13:
            // 110x xxxx   10xx xxxx
            char2 = array[i++]
            out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f))
            break
          case 14:
            // 1110 xxxx  10xx xxxx  10xx xxxx
            char2 = array[i++]
            char3 = array[i++]
            out += String.fromCharCode(
              ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
            )
            break
        }
      }
      response.data = JSON.parse(out)
      //  如果返回的sc为-4 就代表登录超时，返回登录页面
      // if(response.data.sc== -4){
      //     setTimeout(function (){
      //         window.location.href="/";
      //     },1000)
      // }
    }
    return response
  },
  (error) => {
    if (error.response) {
      switch (error && error.response && error.response.status) {
        case 400:
          break
        case 401:
          break
        case 403:
          break
        case 404:
          break
        case 408:
          break
        case 500:
          break
        case 501:
          break
        case 502:
          break
        case 503:
          break
        case 504:
          break
        case 505:
          break
        default:
      }
      return error
    }
  }
)
export default axios
