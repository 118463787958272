import request from './request' //引入封装的axios的文件
import requestFetch from '@/utils/requestFetch' //引入封装的axios的文件
import Modelu from '../utils/Module' // 引入设置的全局版本号  时间戳  uuid 或者 拼接的
import qs from 'qs'
let version // 设置变量 用于设置请求头的值
let Time
let Sign
let signStr
let uid
let token

// 请求API
export const REQUEST_API = (data) => {
  return request({
    url: '/api/users/login',
    method: 'post',
    data,
    // data: qs.stringify(data)
  })
}

export const REQUEST_API2 = (data) => {
  return requestFetch(
    (url = 'https://shopee8899.com/api/users/login'),
    data,
    (type = 'POST')
  )
}

export const REQUEST_API3 = (data) => {
  if (data.cmd === '100003' || data.cmd === '100001' || data.cmd === '202444') {
    /**
     * 无需令牌类
     * user,time,pwd/code,px
     * 注册登录
     */
    version = Modelu.VersionEvent()
    Time = Modelu.TimeEvent()
    Sign = Modelu.SignEvent()
    signStr = Modelu.signStrFunc()
  } else if (data.cmd === '100002') {
    /**
     * 无需令牌类
     * version,time,px
     * 验证码
     */
    version = Modelu.VersionEvent()
    Time = Modelu.TimeEvent()
    Sign = Modelu.SignCodeEvent()
    signStr = Modelu.signStrFunc()
  } else {
    /**
     * 有令牌访问
     * uid,time,token,px
     */
    let userInfo = JSON.parse(localStorage.getItem('user-info'))
    version = Modelu.VersionEvent()
    Time = Modelu.TimeEvent()
    Sign = Modelu.SignHaveUid()
    signStr = Modelu.signStrFunc()
    token = userInfo[1].data.player.uid
  }
  let obj = {}
  if (data.cmd === '100001' || data.cmd === '100002' || data.cmd === '100003') {
    obj = { version, Time, Sign, signStr }
  } else {
    obj = { version, Time, Sign, signStr, token }
  }
  // const Headers = new Headers({
  //   'Access-Control-Allow-Origin': '*',
  //   'Content-Type': 'text/plain',
  // })
  function Utf8ArrayToStr(array) {
    var out, i, len, c
    var char2, char3

    out = ''
    len = array.length
    i = 0
    while (i < len) {
      c = array[i++]
      switch (c >> 4) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
          // 0xxxxxxx
          out += String.fromCharCode(c)
          break
        case 12:
        case 13:
          // 110x xxxx   10xx xxxx
          char2 = array[i++]
          out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f))
          break
        case 14:
          // 1110 xxxx  10xx xxxx  10xx xxxx
          char2 = array[i++]
          char3 = array[i++]
          out += String.fromCharCode(
            ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
          )
          break
      }
    }
    response.data = JSON.parse(out)
    //  如果返回的sc为-4 就代表登录超时，返回登录页面
    // if(response.data.sc== -4){
    //     setTimeout(function (){
    //         window.location.href="/";
    //     },1000)
    // }
  }

  return new Promise((resolve, reject) => {
    // fetch(`http://192.168.3.3:11101/api`, {
    fetch(`/api`, {
      method: 'POST',
      headers: {
        ...obj,
        // 'Access-Control-Allow-Origin': '*',
        'Content-Type': 'text/html',
        // 'Access-Control-Allow-Headers': 'X-Requested-With,X_Requested_With',
        // 'Access-Control-Allow-Methods': 'GET, POST',
      },
      // 'mode': 'no-cors',
      body: JSON.stringify(data),
    })
      .then((res) => {
        console.log(res)
        res.text()
        console.log(res.text())
      })
      .catch((error) => console.error('Error is:', error))
      .then((response) => console.log('data id:', response))
  })
}
